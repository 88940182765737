/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import "./Navbar.css";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";

import { useState } from "react";

import logo from "./logo-white.png";
import { HashLink } from "react-router-hash-link";

function NavbarLink(props) {
  return (
    <li
      className={
        "navbar__list-element" +
        (props.currentPath === props.linkURL
          ? " navbar__list-element--active"
          : "")
      }
    >
      <Link to={props.linkURL} className="navbar__link">
        {props.children}
      </Link>
    </li>
  );
}

function HashNavbarLink(props) {
  return (
    <li
      className={
        "navbar__list-element" +
        (props.currentPath === props.linkURL
          ? " navbar__list-element--active"
          : "")
      }
    >
      <HashLink smooth to={props.linkURL} className="navbar__link">
        {props.children}
      </HashLink>
    </li>
  );
}

function Navbar({ data }) {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className={"navbar"}>
      <div className="navbar__main-wrapper">
        <Link to="/">
          {" "}
          <div
            style={{ marginLeft: "60px", height: "60px", overflow: "hidden" }}
          >
            <img
              src={logo}
              style={{ height: "100%", width: "auto", overflow: "hidden" }}
            />
          </div>
        </Link>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="navbar__hamburger"
        >
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
        </button>
      </div>
      <nav
        className={"navbar__menu" + (isMenuOpen ? " navbar__menu--active" : "")}
      >
        <ul className="navbar__list">
          <NavbarLink currentPath={location.pathname} linkURL={`/about-us`}>
            {data.links[0]}
          </NavbarLink>
          <NavbarLink currentPath={location.pathname} linkURL={`/career`}>
            {data.links[1]}
          </NavbarLink>
          <HashNavbarLink
            currentPath={`/${location.hash}`}
            linkURL={`/#contact`}
          >
            {data.links[2]}
          </HashNavbarLink>
          <NavbarLink currentPath={location.pathname} linkURL={`/eou`}>
            {data.links[3]}
          </NavbarLink>
        </ul>
      </nav>
    </header>
  );
}

export default Navbar;
