import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <p className="footer__copyright">
        Copyright © {new Date().getFullYear()}{' '}
        <span className="footer__bold-span">DreamAi Technologies</span> |
        <a
          target="_blank"
          href="/privacy.pdf"
          className="footer__privacy-policy-link"
        >
          {' '}
          Privacy policy
        </a>
      </p>
      <p
        className="footer__copyright"
        style={{ fontSize: '12px', marginTop: '4px' }}
      >
        DreamAi Technologies p.s.a. Krucza 16 / 22, 00-526 Warsaw, Poland KRS
        0001096389
      </p>
    </footer>
  );
}

export default Footer;
