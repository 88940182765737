import "./AboutUsAboutSection.css";
import React from "react";

export default function AboutUsAboutSection({ data }) {
  return (
    <div className="aboutUs-page___container">
      <div className="aboutUs-page___content">
        <p className="aboutUs-page__about-text">{data.textUp}</p>
        <p className="aboutUs-page__about-text">{data.textDown}</p>
      </div>
    </div>
  );
}
