import englishFlag from "../data/languages/angielski.png";

export const polish = {
  navbar: {
    links: ["O nas", "Kariera", "Kontakt"],
    aboutUs: ["Nasze produkty", "Eureka", "Inne projekty"],
    languages: { link: englishFlag, lang: "English" },
  },
  homePage: {
    heroSection: {
      title: "Mów z pewnością siebie w nowym języku.",
      subtitle: "Zobacz więcej",
    },
    aboutSection: {
      tittle: "O nas",
      subtitle: `Dzięki naszym produktom możesz uczyć się języków obcych w sposób łatwy i
      naturalny, bez wysiłku, bez rygorystycznych metod nauczania i mnóstwa zadań
      domowych, w dowolnym miejscu i czasie. Jesteśmy fantastycznym zespołem
      utalentowanych ludzi, którzy kochają swoją pracę. Głęboko wierzymy, że tylko
      szczęśliwi ludzie mogą tworzyć wysokiej jakości, użyteczne aplikacje, dlatego
      nasza firma robi wszystko, aby każdy pracownik mógł ujawnić swój talent, czuć
      się pewnie i komfortowo. Wierzymy, że ważne jest, aby zostać wysłuchanym i
      mieć bezpośredni wpływ na rozwój produktu.`,
      catchWords: `Europejska Agencja Edukacji jest zaangażowana w

      zmianę życia ludzi dzięki potędze języka.`,
    },
    offerSection: {
      title: "Uczestnicy naszych kursów osiągają poprawę w zakresie:",
      paragrafOne: "Poszerzenia zasobu słownictwa",
      paragrafTwo: "Doskonalenia gramatyki",
      paragrafThree: "Rozwijania umiejętności pisania",
      paragrafFour: "Poprawy umiejętności czytania",
      text: `W dzisiejszym świecie
      nauczanie online jest
      najskuteczniejszym
      sposobem nauki
      nowego języka.`,
    },
    contactSection: {
      title: "Skontaktuj się z nami",
      name: "Imię",
      lastName: "Nazwisko",
      email: "adres e-mail",
      message: "Treść wiadomości",
      submit: "Wyślij",
    },
  },
  eureka: {
    hero: {
      headline: "Eureka",
    },
    slogan: {
      text: `Łącząc najlepsze cechy sztucznej inteligencji i badań
      nad językiem, lekcje są precyzyjnie dopasowane, aby
      pomóc Ci w nauce na odpowiednim poziomie i w
      odpowiednim tempie.`,
    },
    about: {
      paragraphOneTitle: `Nauka języka nie jest
      trudna`,
      paragraphOneText: `Dowiedz się, jak radzić sobie w prawdziwych, codziennych sytuacjach w nowym
      języku. Nauczysz się tylko praktycznych i przydatnych umiejętności, które możesz
      od razu zastosować i szybko osiągnąć cel, jakim jest prowadzenie rozmów w
      świecie codziennym.`,
      paragraphTwoTitle: `Krótkie lekcje. Zwroty z życia wzięte`,
      paragraphTwoText: `To wszystko, czego potrzebujesz, aby szybko przejść od nauki do mówienia.`,
      paragraphThreeTitle: `Skuteczna nauka językow obcych`,
      paragraphThreeText: `Immersyjna nauka, która sprawi, że poczujesz się tak, jakbyś był z miejscową
      ludnością Ucz się więcej i szybciej dzięki algorytmowi uczenia się opracowanemu
      z wykorzystaniem najlepszych odkryć nauk kognitywnych. Znajdź tematy treści,
      które odpowiadają Twoim potrzebom: od rozmów o wakacjach po poznawanie
      rodziny partnera.`,
    },
    features: {
      featureOne: "Jedno konto",
      featureTwo: "3 Poziomy",
      featureThree: "Multiple devices",
      text: `Chcesz dowiedzieć
      się więcej?`,
      link: "https://eureka-online.eu/pl",
    },
  },
  otherProject: {
    hero: { headline: "Bądźcie czujni!" },
    about: {
      title: "Inne projekty",
      text: `Pracujemy tak ciężko po części dlatego, że czujemy, iż jesteśmy częścią czegoś
      większego niż my sami. Wierzymy, że misja organizacji jest zgodna z naszymi
      wartościami i celami, a nasza praca stanowi ważną część realizacji tej większej
      misji instytucjonalnej.`,
    },
    progress: {
      text: `Pracujemy nad
      czymś
      niesamowitym.
      Bądźcie czujni!`,
    },
  },
  about: {
    hero: { headline: "O nas" },
    about: {
      textUp: `W każdym pracowniku cenimy jego indywidualność, niezależność opinii, umiejętność brania
      odpowiedzialności za rozwiązywanie spraw i problemów. Każdy ma swój własny obszar
      odpowiedzialności. Bezkompromisowa jakość, współpraca, świeże pomysły, przyjazna atmosfera w
      firmie - wszystko to wpływa na nasze produkty i ich tworzenie.`,
      textDown: ``,
    },
    company: {
      title: `pracujemy zespołowo`,
      text: `Jesteśmy zabawni, ciekawi świata i pełni pasji do zmieniania krajobrazu nauki języków obcych.
      Wiemy, że w pracy spędzasz więcej czasu niż w domu, więc chcemy, abyś czerpał z niej jak
      najwięcej przyjemności.`,
      title1: `Nasza koncepcja nauczania`,
      paragraphOne: `Wierzymy, że każdy może nauczyć się języka dzięki produktom EEA. Nasze niewielkie lekcje
      przypominają bardziej grę niż podręcznik, i jest to celowe: Nauka jest łatwiejsza, gdy dobrze się
      bawisz.`,
      paragraphTwo: `Jeśli szukasz podręczników do gramatyki, to być może nie jesteśmy dla Ciebie. Ale jeśli szukasz
      sposobu na naukę bez presji, warto z nami spróbować.`,
      paragraphThree: `Niezależnie od tego, czy jesteś zupełnie początkujący, czy nieco bardziej zaawansowany, jesteśmy
      tutaj, aby Ci pomóc. Nasze programy skupiają się na tym, aby dać Ci pewność siebie, która pozwoli
      Ci wyjść z domu i wykorzystać Twoje umiejętności językowe - bo w końcu po co uczyć się języka,
      jeśli nie zamierzasz go używać?`,
    },
  },
  career: {
    hero: { headline: "Kariera" },
    description: {
      text: `W EEA sprawiamy, że nauka języka staje się łatwiejsza dzięki połączeniu kursów opartych na sztucznej
      inteligencji i natychmiastowej informacji zwrotnej od naszej globalnej społeczności. Jeśli kochasz języki,
      chcesz pracować z inteligentnymi, kreatywnymi i energicznymi ludźmi oraz masz inicjatywę, pewność
      siebie i umiejętność podejmowania samodzielnych decyzji każdego dnia, pokochasz pracę z nami!`,
    },
    company: {
      title: "Wartości naszej firmy",
      data: [
        {
          headline: "Zaufanie",
          text: `Od pierwszego dnia pracy każdy jest odpowiedzialny za swoje
          działania. Zawsze wywiązujemy się z obietnic i traktujemy siebie
          nawzajem z szacunkiem.`,
        },
        {
          headline: "Efektywność",
          text: `Dla nas wzmocnienie pozycji naszych klientów oznacza rozwiązywanie
          właściwych problemów - a następnie wykraczanie poza nie, aby wykonać
          zadania.`,
        },
        {
          headline: "Ambicja",
          text: `Zawsze myślimy perspektywicznie i staramy się robić to, co najlepsze - i
          jesteśmy dumni z tego, że pozostawiamy po sobie ślad.`,
        },
        {
          headline: "Ciekawość",
          text: `Lubimy kwestionować status quo. Testujemy, uczymy się i dzielimy
          się wiedzą, aby każdego dnia ulepszać siebie i swoją pracę.`,
        },
      ],
    },
    job: {
      title: "Dostępne stanowiska",
      paragraphOneTitle: "TECHNOLOGY",
      paragraphOneLiOne: "IT Specialist",
      paragraphOneLiTwo: "QA Engineer",
      paragraphTwoTitle: "DESIGN",
      paragraphTwoLiOne: "Product Designer",
      paragraphThreeTitle: "BUSINESS",
      paragraphThreeLiOne: "Accountant",
      paragraphThreeLiTwo: "Sales Operations Manager",
      textUp: `Skontaktuj się z nami! Zawsze cieszymy się, gdy możemy
        przywitać się z kimś nowym.`,
      textDown: `Zastrzegamy sobie prawo do skontaktowania się tylko z
        wybranymi osobami.`,
    },
    section: {
      title: "Skontaktuj się z nami",
      name: "Imię",
      lastName: "Nazwisko",
      email: "adres e-mail",
      message: "Treść wiadomości",
      submit: "Wyślij",
    },
  },
};
