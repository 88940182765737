import { useState } from "react";
import React from "react";
import { InView } from "react-intersection-observer";
import "./AboutSection.css";

function AboutSection({ data }) {
  const [inView, setInView] = useState(false);
  return (
    <section className="about-section__container" id="about-us">
      <div className="about-section__content-container">
        <div className="about-section__content">
          <h1 className="about-section__page-title">{data.tittle}</h1>
          <InView onChange={setInView}>
            <p className={"about-section__text" + (inView ? " active" : "")}>
              {data.subtitle}
            </p>
          </InView>
        </div>
        <div className="about-section__logo-container"></div>
      </div>
      <div className="about-section__slogan-wrapper">
        <h2 className="about-section__slogan-title">{data.catchWordsTitle}</h2>
        <p className="about-section__slogan">{data.catchWords}</p>
      </div>
    </section>
  );
}

export default AboutSection;
