export const english = {
  navbar: {
    links: ["About us", "Career", "Contact"],
    aboutUs: ["Our Products", "Smartado", "Other Projects"],
  },
  homePage: {
    heroSection: {
      title: "Top-tier language learning experience",
      subtitle: "See more",
    },
    aboutSection: {
      tittle: "About us",
      subtitle: `Our specialists have dedicated themselves to researching the most effective methods of academic learning and have carefully selected empirically verified, well-established approaches to language teaching. They then skillfully tailored comprehensive educational resources for the digital world, culminating in a robust curriculum available at your convenience, regardless of your location or preferred learning schedule.`,
      catchWordsTitle: "From our perspective:",
      catchWords: `Everyone deserves access to quality education and continuous lifelong learning opportunities.
      `,
    },
    offerSection: {
      title: "Why education matters to our society?",
      paragrafOne: "More Employment Opportunities ",
      paragrafTwo: "Higher Income",
      paragrafThree: "Problem-solving Skills",
      paragrafFour: "Prosperous and Happy Life",
      text: `
      Learning foreign languages promotes more positive attitudes and reduces prejudice against people from different backgrounds.`,
    },
    contactSection: {
      title: "Contact us",
      name: "Name",
      lastName: "Last name",
      email: "e-mail adress",
      message: "Message content",
      submit: "Send",
    },
  },
  eureka: {
    hero: {
      text: `In an era of globalization and connectivity, language has become more
      than just a tool for communication; it's a bridge connecting cultures,
      individuals, and passions. As creators of eLingo, we believe that
      multilingualism isn't a luxury, but a necessity for an open,
      understanding, and connected world.`,
      buttonText: `Discover more`,
    },
    highlights: {
      title: "Course Highlights",
      highlights_paragraphs: [
        "Relatable, real-life lessons",
        "Engaging auditory and visual aids",
        "Fun exercises to strengthen memory",
        "Continuous learning mode for advanced learners",
      ],
    },
    vision: {
      title: "Our vision",
      info: "eLingo is our vision of a better tomorrow, where language barriers vanish, and humanity grows together in harmony. Ultimately, we are convinced that when we learn languages, we learn about humanity",
    },
    why_us: {
      title: "Why eLingo?",
      info: "eLingo isn't just a language app. It's a key to explore, experience, and evolve. We believe that language isn't about tedious grammar rules but about real-life communication.",
      benefits_title: "With eLingo:",
      benefits_list: [
        "Grasp words, phrases, and real-world expressions.",
        "Just 30 minutes a day makes a difference.",
        "Avail certificates to validate your proficiency.",
        "Progress through 3 comprehensive learning levels.",
      ],
      facts_title: "Quick Facts",
      facts_list: [
        "Languages Offered: 9",
        "Active Users: 24,000",
        "Lessons Available: 238",
      ],
    },
    slogan: {
      text: `Learning a new language is transformative. It can help you get a better job, a better degree, meet new people and live life changing experiences.`,
    },
    about: {
      paragraphOneTitle: `Speak instantly with confidence`,
      paragraphOneText: `Instead of drilling abstract sentences, vocabulary or grammar rules, the app teaches through real-life dialogue.
      `,
      paragraphTwoTitle: `Retain for the long term`,
      paragraphTwoText: `Whether you learn best by reading, writing, speaking, seeing or listening, SMARTADO addresses all individual needs for both visual and auditory learning types.
      `,
      paragraphThreeTitle: `Bridging the Borders
      `,
      paragraphThreeText: `SMARTADO helps connect with people and organizations around the world. Borders are no longer there. Being able to communicate and share opinions with people from other countries and cultures and widens horizons.
      `,
    },
    features: {
      featureOne: "9 languages",
      featureTwo: "17000 users",
      featureThree: "160 lessons",
      text: `Would you like to find out more? `,
      link: "https://smartado.eu/en",
    },
  },
  otherProject: {
    hero: { headline: "Stay tuned!" },
    about: {
      title: "Other Projects",
      text: `We work so hard, in part, because we feel that we are part of something bigger
        than ourselves. We believe that the mission of the organization aligns to our own
        values and goals, and that our work is an important part of achieving that larger
        institutional mission.`,
    },
    progress: {
      text: `We are working on
        something awesome.
        Stay tuned!`,
    },
  },
  about: {
    hero: { headline: "About us" },
    about: {
      textUp: `
      Our strategy? Cultural value serves as an intangible catalyst to propel individuals, brands and societies toward progress. It stems from what matters most to people and is cultivated through the actions of organizations around the world. Influential concepts ignite, earn and engage individuals around the world.`,
      textDown: `It is born from what matters most to people, and earned by how an organization shows up in the world. Effective ideas inspire, earn and activate people around the globe.

        `,
    },
    company: {
      title: "AT DreamAi WE VALUE",
      subtitle: `Competence`,
      text: `From the very beginning, we entrust everyone with responsibility. We consistently fulfill our commitments and ensure mutual respect`,
      subtitle1: ` Advancements & Novelty`,
      paragraphOne: `As we strive to be recognized as a technology leader in our industry, we are dedicated to prudent investment in the right products, systems and equipment, striving for excellence.`,
      paragraphTwo: `Our goal is to instill a culture of creativity, innovation and inspiration in every employee by creating a climate that encourages thoughtful risk-taking.`,
      subtitle2: `Expansion & Financial Success`,
      paragraphThree: `Our goal is to ensure sustainable, balanced growth for our company. We take a balanced and prudent approach to investment and spending to drive our expansion.`,
      paragraphFour: `We recognize that continued profitability is critical to the longevity of our organization.`,
    },
  },
  career: {
    hero: {
      headline: "Career",
      hash: "#open-positions",
      text: "See open positions",
    },
    description: {
      text: `At DreamAi, we simplify language learning by integrating top-notch courses with instant feedback from our community around the world. If you are passionate about languages, eager to work with smart, innovative and enthusiastic people, and equipped with the initiative, confidence and discernment to make independent decisions every day, you will thrive in our dynamic environment!
      `,
    },

    company: {
      title: "AT DreamAi WE VALUE",
      data: [
        {
          headline: "Trust",
          text: `From day one, we entrust everyone with responsibility. We consistently deliver on our promises and nurture mutual respect.`,
        },
        {
          headline: "Effectiveness",
          text: `For us, empowering our customers means solving the right problems –
                then going above and beyond to get stuff done.`,
        },
        {
          headline: "Ambition",
          text: `We always think big and try to go the extra mile – and we pride ourselves
                on leaving a legacy.`,
        },
        {
          headline: "Curiosity",
          text: `We like to question the status quo. We test, learn and share so we
                can better ourselves and our work every day.`,
        },
      ],
    },
    job: {
      // title: "OPEN POSITIONS",
      // paragraphOneTitle: "Call centre operator with Romanian",
      // paragraphOneLiOne: "Place of work: remote",
      // paragraphOneLiTwo: "Type of employment: full-time, part-time",
      // paragraphTwoTitle: "Job description",
      // paragraphTwoLiOne:
      //   "Telephone sales of the company's products to our customers      ",
      // paragraphTwoLiTwo: "Confirming customer orders and increasing sales",
      // paragraphTwoLiThree:
      //   "Maintaining positive relationships with customers and ensuring their satisfaction",
      // paragraphThreeTitle: "Required",
      // paragraphThreeLiOne: "Interpersonal communication at a high level      ",
      // paragraphThreeLiTwo:
      //   "Enthusiasm and energy in discussions with customers      ",
      // paragraphThreeLiThree:
      //   "Enthusiasm and energy in discussions with customers      ",
      // paragraphFourTitle: "Required:",
      // paragraphFourLiOne: "Interpersonal communication at a high level",
      // paragraphFourLiTwo: "Enthusiasm and energy in discussions with customers",
      // paragraphFourLiThree: "English at a communicative level (B1)",
      // paragraphFiveTitle: "We offer:",
      // paragraphFiveLiOne:
      //   "Base salary for every hour worked + high sales commissions (chance to double your salary)        ",
      // paragraphFiveLiTwo: "Comfortable working from home",
      // paragraphFiveLiThree:
      //   "Flexible working hours (minimum 20 hours per week from 9am to 8pm)",
      // paragraphFiveLiFour:
      //   "Opportunity for rapid development within our organisation - promotion to Team Leader",
      // paragraphSixTitleSix: "Language skills",
      // paragraphSixLiOne:
      //   "Hungarian/Romanian/Croatian/Lithuanian/Slovak - fluent (C2)",
      // paragraphSIxLiTwo: "English (B1)",
      // textUp: `Get in touch! We’re always delighted to say hello to a fresh face.`,
      // textDown: `We reserve the right to contact only selected individuals.`,
    },
    section: {
      title: "Contact us",
      name: "Name",
      lastName: "Last name",
      email: "e-mail adress",
      message: "Message content",
      submit: "Send",
    },
  },
};
